import {
  ADD_CUSTOMER,
  CLEAR_CUSTOMER,
  ORDER_CUSTOMER_STORAGE,
} from './Constants.js';

const initialState = {};

export const initializer = (initialValue = initialState) =>
 // JSON.parse(localStorage.getItem(ORDER_CUSTOMER_STORAGE)) ||
  initialValue;


export const reducer = (state, action) => {
  switch (action.type) {
    case ADD_CUSTOMER:
      return { customer: action.item };
    case CLEAR_CUSTOMER:
       return { customer: {}};;

    default:
      return state;
  }
};
