import React, { createContext, useEffect, useReducer } from 'react';

import { ORDER_CUSTOMER_STORAGE } from './Constants.js';
import { initializer, reducer} from './reducer.js';
export const BasketCustomerContext = createContext();

export const BasketCustomerProvider = ({ children }) => {
  const [basketCustomer,dispatch] = useReducer(reducer, {customer:{}}, initializer);

  useEffect(() => {       
   // localStorage.setItem(ORDER_CUSTOMER_STORAGE, JSON.stringify(basketCustomer));
  }, [basketCustomer]);

  return (
    <BasketCustomerContext.Provider
      value = {{
        basketCustomer,
        dispatch
      }}
    >
      {children}
    </BasketCustomerContext.Provider>
  );
};
